<template>
  <div class="cv-button">
    <div class="box">
      <div class="balloon">
        <p>
          <SvgIcon
            type="mdi"
            class="icon"
            :path="mdiThumbUp"
            size="16"
          />
          Hubungi kami. <span>▶▶▶</span>
        </p>
      </div>
      <a
        class="button"
        target="_blank"
        @click="fbEvent(formLink)"
      >
        <SvgIcon
          type="mdi"
          class="icon"
          :path="mdiFileDocumentEditOutline"
          size="24"
        />
        Konsultasi Web Gratis
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({});
import SvgIcon from '@jamescoyle/vue-icon';
import {formLink} from "@/constants/Items"
import {mdiThumbUp, mdiFileDocumentEditOutline } from '@mdi/js'

const fbEvent = (href:string) => {
  fbq('track', 'Contact');
  window.open(href, '_blank');
}
</script>

<style scoped lang="scss">
.box{
  max-width: 620px;
  margin: auto;
}
.cv-button{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  z-index: 2;
}
.button{
  width: 100%;
  border: solid 3px #27aeff;
  background-color: #27aeff;
  box-shadow: 0 4px #3086bb;
  color: #fff;
  height: 48px;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 4px;
  &:active{
    box-shadow: 0 0 #24427b;
    transform: translateY(4px);
  }
  svg {
    margin-right: 4px;
  }
}

.balloon {
  position: relative;
  margin: 0 0 10px;
  padding: 4px 12px;
  min-width: 120px;
  max-width: 100%;
  color: #fff;
  font-size: 16px;
  background: #2d8c3c;
  border-radius: 8px;
  text-align: center;
  svg {
    vertical-align: middle;
  }
  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    border: 8px solid transparent;
    border-top: 8px solid #2d8c3c;
  }
  span{
    font-size: 10px;
    vertical-align: text-bottom;
  }
}
</style>
