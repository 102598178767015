<template>
  <section class="interview">
    <h3 class="title">
      <!-- 聞きました -->
      <!-- お子さまを塾に通わせた経験のある方 -->
      <span><em>Kami bertanya</em></span>
      <span class="lead">kepada para alumni LPK.</span>
    </h3>
    <section class="reason">
      <!-- 通っているLPKを選んだ理由は？ -->
      <h4 class="sub-title">
        <span class="lead">Mengapa Anda memilih</span>
        <span>LPK yang Anda ikuti?</span>
      </h4>
      <p class="img">
        <img
          alt=""
          src="@/assets/img/reason.png"
        >
      </p>
      <div class="focus">
        <div class="inner">
          <img
            alt=""
            class="laurel"
            src="@/assets/img/LpInterview/laurel.png"
          >
          <ol>
            <!-- 立地 -->
            <li class="top">
              <span>1.</span>Lokasi
            </li>
            <!-- 料金 -->
            <li class="top">
              <span>2.</span>Harga
            </li>
            <!-- 日本に行くまでにかかる期間 -->
            <li class="top">
              <span>3.</span>Lama waktu yang dibutuhkan untuk sampai ke Jepang
            </li>
            <!-- 送り出し実績 -->
            <li><span>4.</span>Rekam jejak pengiriman siswa ke Jepang</li>
            <!-- 先生の質（日本人がいるか） -->
            <li><span>5.</span>Kualitas pengajar (apakah ada orang Jepang?)</li>
            <!-- カリキュラム -->
            <li><span>6.</span>Kurikulum</li>
          </ol>
          <p class="notice">
            <!-- 調べきれずに塾選びで後悔している人は
            なんと4人のうち1人 -->
            <b>Orang-orang yang menyesali pilihan sekolah mereka karena kurangnya penelitian.</b>
            <em>1 dari 4 orang</em>
          </p>
        </div>
      </div>
    </section>
    <section class="regret">
      <!-- 塾選びで後悔している理由の上位3つ -->
      <h4>
        <em>Mengapa Anda menyesal</em> memilih sekolah bimbingan belajar?
      </h4>
      <div
        class="box"
        :style="{ backgroundImage: `url(${require('@/assets/img/LpInterview/box.png')})` }"
      >
        <!-- 想定した料金と実際かかる金額が違う
        日本語を学んだが、仕事がなく日本にいけない。
        紹介される職場が劣悪。 -->
        <ol>
          <li>1. Harga yang saya harapkan berbeda dengan harga yang sebenarnya.</li>
          <li>2. Saya belajar bahasa Jepang, tetapi saya tidak bisa pergi ke Jepang karena tidak ada pekerjaan.</li>
          <li>3. Tempat kerja yang diperkenalkan kepada saya buruk.</li>
        </ol>
      </div>
      <div class="comment">
        <!-- 後悔した人は
        こうしたらよかった！
        と思っている -->
        <p class="lead">
          Orang yang menyesal berharap <br>
          <em>mereka melakukan hal ini</em>
        </p>
        <ul>
          <!-- 送り出しのライセンスを持っているLPKに行けばよかった… -->
          <li class="no-1">
            <p>
              Seharusnya saya…<br>
              pergi ke LPK yang terjamin dan <em>memiliki lisensi resmi</em> untuk pengiriman ke Jepang.
            </p>
            <div
              class="person"
              :style="{ backgroundImage: `url(${require('@/assets/img/LpInterview/no-1.png')})` }"
            />
          </li>
          <!-- 複数のLPKを料金、仕事、比較するとよかった… -->
          <li class="no-2">
            <p>
              Seharusnya saya…<br>
              membandingkan beberapa LPK, dari segi harga, tetapi juga <em>peluang kerja dan kualitas pengajaran</em>.
            </p>
            <div
              class="person"
              :style="{ backgroundImage: `url(${require('@/assets/img/LpInterview/no-2.png')})` }"
            />
          </li>
          <!-- 身近にいる人から聞いた話だけでなく、過去のOBなどから話を聞けばよかった… -->
          <li class="no-3">
            <p>
              Seharusnya saya…<br>
              mendengarkan pengalaman dari orang-orang terdekat serta <em>alumni yang sudah sukses</em> agar mendapat informasi yang lebih akurat.
            </p>
            <div
              class="person"
              :style="{ backgroundImage: `url(${require('@/assets/img/LpInterview/no-3.png')})` }"
            />
          </li>
        </ul>
      </div>
    </section>
    <section class="lets">
      <!-- このサイトのメリット -->
      <h4>
        Keuntungan dari situs web ini
      </h4>
      <ul>
        <!-- 日本人が直接LPKに訪問し、評価している。 -->
        <li
          :style="{ backgroundImage: `url(${require('@/assets/img/LpInterview/icon-building.png')})` }"
        >
          <span><em>Tim dari Jepang</em> telah mengunjungi dan mengevaluasi <em>LPK</em> secara langsung untuk memastikan kualitasnya.</span>
        </li>
        <!-- 複数のLPKを比較できることで、自分にあったLPKを選ぶことができる。 -->
        <li
          :style="{ backgroundImage: `url(${require('@/assets/img/LpInterview/icon-statistics.png')})` }"
        >
          <span><em>Fitur</em> perbandingan <em>LPK</em> membantu Anda memilih yang paling sesuai dengan kebutuhan Anda.</span>
        </li>
        <!-- LPKによってOBからも話を聞くことができる。 -->
        <li
          :style="{ backgroundImage: `url(${require('@/assets/img/LpInterview/icon-calendar.png')})` }"
        >
          <span>Anda dapat mendengar <em>pengalaman</em> langsung dari <em>alumni</em> yang sudah sukses melalui LPK tersebut.</span>
        </li>
        <!-- 気になった塾に行って体験、見学することができる。 -->
        <li
          :style="{ backgroundImage: `url(${require('@/assets/img/LpInterview/icons-medal.png')})` }"
        >
          <span>Kunjungi dan rasakan langsung <em>suasana</em> sekolah yang Anda <em>minati</em> sebelum membuat keputusan</span>
        </li>
      </ul>
    </section>
  </section>
</template>

<script setup>
</script>

<style scoped lang="scss">
.interview{
  background-color: #f0f0f0;
  padding-top: 8px;
}
.title{
  text-align: center;
  background-color: #fff;
  padding: 24px 12px;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  color: #237b31;
  gap:8px;
  .lead{
    font-size: 16px;
    color: #333;
  }
  em{
    color: #fe8610;
    font-style: normal;
  }
}
.sub-title{
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 24px 0 8px;
  font-size: 24px;
  gap:8px;
  .lead{
    font-size: 16px;
  }
}

.reason {
  .img {
    padding: 0px 16px 16px;
  }
}

.focus{
  padding: 8px;
  background-color: #82be28;
  border-radius: 10px;
  margin: 0px 16px 16px;
  text-align: center;
  font-size: 12px;
  .inner{
    background-color: #fff;
    border-radius: 10px;
    padding: 16px;
    position: relative;
  }
  .laurel{
    position: absolute;
    z-index: 0;
    width: 200px;
    left: 50%;
    margin: -100px;
    opacity: 0.35;
    top: 35%;
  }
  ol{
    position: relative;
    z-index: 1;
    margin: 0 auto 12px;
    list-style: none;
    padding: 0;
    font-weight: 700;
    font-size: 16px;
    width: 260px;
    .top{
      color: #237b31;
    }
    span{
      width: 22px;
      display: inline-block;
    }
  }
}
.notice{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: red;
  font-weight: bold;
  b{
    color: #333;
  }
  em{
    font-style: normal;
  }
}

.regret {
  background: #fff;
  padding: 16px 16px 38px;
  text-align: center;
  h4{
    padding: 4px 0 16px;
    font-size: 16px;
    line-height: 1.2;
    em{
      font-style: normal;
      font-size: 24px;
      color: #237b31;
    }
  }
  .box{
    background-color: #f0f0f0;
    padding: 16px 16px 74px;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: auto 64px;

    ol {
      margin: 0;
      text-align: left;
      padding: 0;
      list-style: none;
      text-indent: -1.2em;
      padding-left: 1.2em;
    }
  }
}

.comment {
  .lead {
    font-weight: 700;
    padding: 16px 0;
    em {
      font-style: normal;
      color: #fe8610;
      font-size: 18px;
    }
  }
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
  }
  li {
    position: relative;
    margin-top: 16px;
    padding-right: 68px;
    &:first-child{
      margin-top: 0;
    }
    em {
      font-style: normal;
      color: #de0000;
    }
  }
  li p{
    text-align: left;
    border-radius: 32px;
    font-weight: 700;
    padding: 16px;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      bottom: 0;
      right: -10px;
      width: 25px;
      height: 25px;
      border-radius: 25px;
    }
  }
  .no-1 p{
    background: #ffe8d8;
    &:before{
      background: #ffe8d8;
    }
  }
  .no-2 p{
    background: #e1eee3;
    &:before{
      background: #e1eee3;
    }
  }
  .no-3 p{
    background: #ecf5e1;
    &:before{
      background: #ecf5e1;
    }
  }
  // .no-4 p{
  //   background: #fff5d8;
  //   &:before{
  //     background: #fff5d8;
  //   }
  // }
  .person{
    position: absolute;
    width: 78px;
    height: 78px;
    bottom: 0;
    right: 0px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.lets{
  background-color: #2d8c3c;
  color: #fff;
  text-align: center;
  padding: 16px;
  position: relative;
  &:after{
    content: "";
    position: absolute;
    bottom: 100%;
    left: 42px;
    margin-left: -8px;
    border: solid transparent;
    border-bottom-color: #2d8c3c;
    border-width: 0 16px 24px 16px;
    transform: skew(-40deg);
  }

  .person{
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    background-size: contain;
    z-index: 0;
    margin-left: -175px;
  }
  h4 {
    font-size: 18px;
    margin-bottom: 16px;
  }
  ul{
    padding: 0;
    list-style: none;
    color: #333;
    display: grid;
    margin: 0;
    grid-gap: 8px;
    text-align: left;
    // grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  li {
    background-color: #fff;
    border-radius: 4px;
    width: auto;
    font-size: 12px;
    font-weight: 700;
    padding: 12px 12px 12px 58px;
    min-height: 64px;
    background-size: 40px;
    background-position: 10px center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
  }
  em {
    font-style: normal;
    color: #de0000;
  }
}
</style>
